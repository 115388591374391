import {
  Amount,
  AmountType,
  CalculationMethod,
  QuotaCalculationInput,
  Telematics,
} from 'schema/serverTypes';
import { CalculatorFormValues } from './types';

const getAmount = (amount: Amount | undefined) => {
  if (amount === undefined) {
    return undefined;
  }

  const { type, value } = amount;

  if (type === undefined) {
    if (value === undefined) {
      return undefined;
    } else {
      return { value, type: AmountType.Percents };
    }
  }

  return amount;
};

export const getQuotaCalculationInput = (values: CalculatorFormValues) => {
  const {
    save,
    calculationMethod,
    itemPrice,
    numberOfItems,
    numberOfMonths,
    prepayment,
    itemDiscount,
    subsidyVendor,
    subsidyDealer,
    vendor,
    dealerContractId,
    vendorContractId,
    agentFee,
    agentFeeRecipient,
    insuranceRatePercents,
    telematicsOneTimeAmount,
    telematicsMonthlyAmount,
    tradeFee,
    residualValue,
    marginPercents,
    cofPercents,
    cofAddPercents,
    vatPercents,
    calculationMethodType,
    irrPercents,
    rizeInPricePercents,
    currencySale,
    currencyRate,
    currencyLease,
    leaseItemCost,
    tradeFeeAmount,
    generalRatePercents,
    fundingAmount,
    dealer,
    lessee,
    insuranceCompany,
    hasResidualValue,
    hasInsurance,
    seasonalPaymentOptions,
    paymentOptions,
    nomenclatures = [],
    industryLeasingProductId,
    isAgentFeeWithNds,
    ...rest
  } = values;
  const { agreement: agg, lesseeName, ...agreement } = rest;

  const request: QuotaCalculationInput = {
    calculationMethod,
    calculationMethodType,
    itemPrice,
    numberOfItems,
    numberOfMonths,
    prepayment: getAmount(prepayment),
    itemDiscount: getAmount(itemDiscount),
    subsidyVendor: getAmount(subsidyVendor),
    subsidyDealer: getAmount(subsidyDealer),
    vendor,
    dealerContractId,
    vendorContractId,
    agentFee: getAmount(agentFee),
    agentFeeRecipient,
    insuranceRatePercents,
    telematicsOneTimeAmount:
      agreement.telematics === Telematics.None ? undefined : telematicsOneTimeAmount,
    telematicsMonthlyAmount:
      agreement.telematics === Telematics.None ? undefined : telematicsMonthlyAmount,
    tradeFee,
    residualValue: getAmount(residualValue),
    hasResidualValue,
    hasInsurance,
    marginPercents,
    cofPercents,
    cofAddPercents,
    vatPercents,
    agreement,
    currencySale,
    currencyRate,
    currencyLease,
    leaseItemCost,
    dealer,
    lessee,
    lesseeName,
    insuranceCompany,
    seasonalPaymentOptions,
    paymentOptions:
      calculationMethod === CalculationMethod.Seasonal
        ? paymentOptions
        : calculationMethod === CalculationMethod.SuperSeasonal
        ? paymentOptions?.slice(0, 12)
        : undefined,
    rizeInPricePercents,
    irrPercents,
    nomenclatures,
    industryLeasingProductId,
    isAgentFeeWithNds,
  };

  return request;
};
